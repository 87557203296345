import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Retreatsbreadcreumb from "../components/retreats/retreats-breadcumbs";

import Retreatbanner from "../components/retreats/retreat-banner";

import Retreatcontentpage from "../components/retreats/retreat-content";

// markup
const RetreatPage = () => {
  return (
    <div>
      <Seo title="Retreats" />

      <Layout isSticky>
        <section>
          <Retreatsbreadcreumb />
        </section>
        <section>
          <Retreatbanner />
        </section>
        <section>
          <Retreatcontentpage />
        </section>
      </Layout>
    </div>
  );
};

export default RetreatPage;

import React from "react";

import retreatbannergirl from "../../assets/images/retreats/heartfulness_retreat_img.png";

export default function Retreatbanner() {
  return (
    <section className="retreatbanner-wrapper d-block retreat-banner pad-60">
      <div className="container px-4">
        <div className="row">
          <div className="col-md-6 p-5">
            <h2 className="gotham-bold">HEARTFULNESS</h2>
            <h2 className="gotham-bold">RETREATS</h2>
            <p className="theme-p-tag gotham-light">
              Choose from the different Heartfulness retreats below to suit your
              needs. Move, meditate, get inspired and share from the heart.
            </p>
          </div>
          <div class="col-md-6 text-center pt-5">
            <img
              className="img-fluid height-350 alignnone"
              src={retreatbannergirl}
              alt="girl meditating"
            />
          </div>
        </div>
      </div>
      <div className="cssCrossShape"></div>

    </section>
  );
}

import React from "react";

import { Link } from "gatsby";

import kaushalamretreat from "../../assets/images/retreats/hfn-kaushalam-retreats-img.png";

import wellnessretreat from "../../assets/images/retreats/hfn-wellness-retreats-img.png";

import yogareflect from "../../assets/images/retreats/hfn_yoga_retreat_img.png";

import meditationretreat from "../../assets/images/retreats/hfn_meditation_retreat_img.png";

export default function Retreatcontentpage() {
  return (
    <section className="retreatcontent-wrapper pad60 pad-top50">
      <div className="container">
        <div className="col-md-12">
          <p className="theme-p-tag">
            Practice heartfulness with self-mastery, yoga,{" "}
            
            <a className="link-color" href=""> meditation </a>
            , and wellness. Choose from the different Heartfulness retreats
            below to suit your needs. Meditate, experience, get inspired, and
            share from the heart.
          </p>
          <p className="theme-p-tag">
            As an institute committed to spreading the precious gift of human
            transformation, Heartfulness strives to awaken the love and light
            within every seeker. With carefully designed specialized programs
            and retreats, we help you achieve holistic transformation of your
            body, mind, and spirit. Our yoga and meditation techniques have
            gained widespread use in over 130 countries across the globe. Join
            millions of people that practice{" "}
            

            <Link to="/" class="link-color">
            Heartfulness meditation.
            </Link>


          </p>
        </div>
      </div>
      <div className="container py-5 px-4">
        <div className="row">
          <div className="col-lg-6 mt-md-n5 ml-n3">
            <div className="bg-white r-card ml-5 shadow">
              <img
                className="img-fluid"
                src={kaushalamretreat}
                alt="Kaushamretreat"
              />
              <div className="px-4">
                <h3 className="theme-color mt-3 mb-2">Kaushalam</h3>

                <p class="gotham-light theme-p-tag">
                  Residential program in Personal Mastery in a pristine
                  environment – for entrepreneurs, professionals, leaders, and
                  anybody who wants to achieve self-mastery. Burnout is sadly a
                  common occurrence today across all sections of the working
                  populace – a byproduct of the hectic working pace almost
                  everyone is subject to. However, there is a powerful and
                  lasting solution. With{" "}
                  {/* <a
                    href="https://heartfulness.org/en/kaushalam/"
                    target="blank"
                    rel="noopener noreferrer"
                    className="link-color"
                  >
                    <span>Kaushalam</span>
                  </a> */}
                    <Link to="/kaushalam/" class="link-color">
                    Kaushalam
                 </Link>
                  , you can move from burnout to joy, energise your time and
                  yourself, enhance your work and personal relationships,
                  realign priorities for well-being and fulfilment, and inspire
                  yourself on to more of what is possible. It is an effective
                  program that enables a professional to be an integrated being.
                </p>

                <hr className="theme-color mb-2" />
                <p className="text-right gotham-light theme-p-tag small pb-2">
                 
                  <Link to="/kaushalam/" class="btn theme-color pad-top20-safari">
                  KNOW MORE
                 </Link>
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 pt-5 mt-md-5 ml-n3">
            <div class="bg-white r-card ml-5 shadow">
              <img
                className="img-fluid"
                src={wellnessretreat}
                alt="Kaushamretreat"
              />
              <div class="px-4">
                <h3 class="theme-color mt-3 mb-2">Wellness</h3>
                <p class="theme-p-tag gotham-light">
                  Retreats are individualized programs that help seekers focus
                  on immersing themselves in the serene atmosphere of our
                  various retreat centers, one among which is{" "}
                  <Link
                    to="https://kanha.sahajmarg.org/"
                    target="blank"
                    rel="noopener noreferrer"
                    className="link-color"
                  >
                    <span>Kanha Shanti Vanam</span>
                  </Link>{" "}
                  – a beautiful and tranquil facility located in the suburbs of
                  Hyderabad. It’s a magnificent space that blends the raw beauty
                  of nature with modern facilities that cater to the wellness
                  needs. Experience harmony and enhance your wellness at any of
                  our retreats. Choose any location according to your
                  convenience – Hyderabad, Pune, Malampuzha, or Kharagpur. We
                  bring forth a lifestyle that helps change the perspective,
                  introduces Heartful living, and helps you embrace the
                  principles of Wellness.
                </p>
                <hr class="theme-color mb-2" />
                <p class="text-right gotham-light small pb-2">
                  {/* <a
                    class="theme-color"
                    href="/en/wellness-retreats"
                    target="blank"
                    rel="noopener noreferrer"
                  >
                    KNOW MORE
                  </a> */}

                  <Link to="/wellness-retreats/" class="btn theme-color">
                  KNOW MORE
                 </Link>

                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mt-md-n5 ml-n3">
            <div class="bg-white r-card ml-5 shadow">
              <img
                className="img-fluid"
                src={yogareflect}
                alt="Kaushamretreat"
              />
              <div class="px-4">
                <h3 class="theme-color mt-3 mb-2">Yoga</h3>
                <p class="them-p-tag gotham-light">
                  We offer week long Yoga retreats in some of our Heartfulness
                  centers in India. Discover India, discover Yoga, and relax and
                  rejuvenate in the gentle, soft, and serene surroundings of our
                  centers. The retreats that we offer in our Heartfulness
                  centers in India are designed to allow you to experience
                  nature, inside and out. They offer a calm and relaxed space
                  for exploration, contemplation, and creation. Over a period of
                  seven days and eight nights, you get to not only discover
                  India but also experience the world-renowned practice of Yoga
                  and meditation with Yogic Transmission. Relax, rejuvenate, and
                  free your mind from the shackles of physical limitations and
                  ignorance.
                </p>
                <hr class="theme-color mb-2" />
                <p class="text-right gotham-light small pb-2">
                  <Link
                    class="btn theme-color pr-4 btn-pad0"
                    to="//heartfulness.org/education/yoga-courses/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    BOOK NOW
                  </Link>
                  <Link
                    class="btn theme-color pad-top20-safari"
                    target="_blank"
                    to="/retreats"
                    rel="noopener noreferrer"
                  >
                    KNOW MORE
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-6 pt-5 mt-md-5 ml-n3">
            <div class="bg-white r-card ml-5 shadow">
              <img
                className="img-fluid"
                src={meditationretreat}
                alt="Kaushamretreat"
              />
              <div class="px-4">
                <h3 class="theme-color mt-3 mb-2">Meditation Retreat</h3>
                <p class="theme-p-tag gotham-light">
                  Heartfulness has established six retreat centres across the
                  globe out of which three are located in India, one in the
                  United States, one in Denmark, and one in Malaysia.
                  <br />
                  Experience the transforming effects of meditation in an
                  environment that has been carefully planned and organized to
                  help you achieve the optimum effects. All our retreat centres
                  are designed to welcome seekers into a peacefully silent
                  atmosphere that facilitates deep meditation, contemplation and
                  exploration of your heart-mind space. Come, embrace the
                  quietude, and discover new horizons on your spiritual journey
                  at any of our meditation retreats.
                </p>
                <hr class="theme-color mb-2" />
                <p class="text-right gotham-light small pb-2">
                  <Link
                    class="btn theme-color btn-pad0"
                    to="//www.sahajmarg.org/smww/retreat-center-overview"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    KNOW MORE
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
